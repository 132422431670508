import React from "react";

import { BACKEND_BASE_URL } from "../../constant/constants";

import Layout from "../../components/layout";
import { Seo } from "../../components";
import EventDetail from "../../container/event-detail";

const EventDetailPage = arg => {
  return (
    <Layout location={arg.location}>
      <Seo title="Event Detail" />
      <EventDetail data={arg.serverData.event_info} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const event_info_res = await fetch(
      BACKEND_BASE_URL + "events/" + context.params.uuid
    );

    if (!event_info_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        event_info: await event_info_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default EventDetailPage;
