import React, { useMemo } from "react";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Breadcrumb } from "../components";

const EventDetail = props => {
  const { data } = props;

  const breadcrumbData = useMemo(() => {
    return [
      {
        id: 1,
        name: "Home",
        link: "/",
      },
      {
        id: 2,
        name: "All Events",
        link: "/upcoming-events",
      },
      {
        id: 3,
        name: data?.title,
      },
    ];
  }, [data]);

  return (
    <div className="detail">
      <div className="container">
        <Breadcrumb data={breadcrumbData} />
        <h3 className="detail__title mb-0">{data?.title}</h3>
        <span className="detail__date">
          {`${moment(data?.timestamp).format("DD MMM YYYY")} ${
            data?.timestamp && " - " + moment(data?.timestamp).format("LTS")
          } ${data?.location && " - " + data?.location} ${
            data?.type ? " - " + data?.type : ""
          }`}
          {data?.event_relative_time && " - " + data?.event_relative_time}
        </span>
        <div className="detail__img">
          <img src={data?.image_url} alt={data?.title} />
        </div>
        <p className="detail__desc mt-4">
          <ReactMarkdown
            children={data?.full_description}
            remarkPlugins={[remarkGfm]}
          />
        </p>
      </div>
    </div>
  );
};

export default EventDetail;
